import { TGenericToken } from "../../hooks/useAccount/useAccount.interfaces";
import StorageAdapterGenerator, {
  StorageAdapter,
} from "./StorageAdapterGenerator";

const LegacyTokenStorageAdapter = (
  storageKey: string
): StorageAdapter<string> => {
  return typeof window !== "undefined"
    ? {
        get: () => {
          return localStorage.getItem(storageKey);
        },
        set: (value: string | null) => {
          if (value) {
            localStorage.setItem(storageKey, value);
          } else {
            localStorage.removeItem(storageKey);
          }
        },
        delete: () => {
          localStorage.removeItem(storageKey);
        },
      }
    : {
        get: () => null,
        set: () => {},
        delete: () => {},
      };
};
const USER_AUTH_TOKEN_KEY = "B2CAuth#Token";
export const UserAuthTokenStorageAdapter =
  LegacyTokenStorageAdapter(USER_AUTH_TOKEN_KEY);
const B2B_AUTH_TOKEN_KEY = "B2BAuth#Token";
export const B2BAuthTokenStorageAdapter: StorageAdapter<string> =
  LegacyTokenStorageAdapter(B2B_AUTH_TOKEN_KEY);

export const GENERIC_AUTH_TOKEN_KEY = "Auth#Token";
export const GenericAuthTokenStorageAdapter =
  StorageAdapterGenerator<TGenericToken | null>(
    GENERIC_AUTH_TOKEN_KEY,
    null,
    true
  );
